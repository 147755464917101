@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.dot.content.small-inline {
    display: inline-block;
    text-align: center;
    width: 24px;
    height: 24px;
    font-size: smaller;
}

.dot.content {
    color: white;
    border-color: #606060;
    border-style: solid;
    border-width: 0.1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: larger;
    font-weight: 500;
    /* display: inline-block; */
}

.dot {
    height: 35px;
    width: 35px;
    background-color: #bbb;
    border-radius: 50%;
}

.w-99 {
    width: 99%;
}

.thumbnail {
    position: relative;
    width: 300px;
    height: 300px;
    overflow: hidden;
}

.thumbnail img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.thumbnail img.portrait {
    width: 100%;
    height: auto;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
